<template>
  <v-footer id="dashboard-core-footer">
    <!-- <v-container> -->
      <v-row align="center" no-gutters>
        <v-col cols="12" lg="4">
        </v-col>
        <v-col cols="12" lg="4">
          <v-row>
            <v-col cols="12" lg="6">
              <img
              src="@/assets/images/logo_temp.jpg"
              style="display:block;margin:auto"
              width="293"
              height="67" />
            </v-col>
            <v-col cols="12" lg="6">
              <div class="d-flex flex-column my-1 text-center text-lg-left">
                <div>服務電話：(02)-8913-6162</div>
                <div>服務傳真：(02)-3393-0761</div>
                <div>服務信箱：service@都更平台.tw</div>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="4" class="d-flex flex-column justify-end">
          <div class="body-1 font-weight-light pr-0 pt-lg-10 pr-lg-6 pt-6 text-center text-lg-right">
            &copy; 2020, made with
            <v-icon color="red" size="18"> mdi-heart </v-icon>
            by Thread Tech</a>
          </div>
        </v-col>
      </v-row>
    <!-- </v-container> -->
  </v-footer>
</template>

<script>
export default {
  name: "DashboardCoreFooter",

  data: () => ({}),
};
</script>

<style lang="sass">
#dashboard-core-footer
  a
    font-size: .825rem
    font-weight: 500
    text-decoration: none
    text-transform: uppercase
</style>
